.container {
  width: inherit;
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  background-color: rgba(230, 230, 230, 0.24);
}

.sectionContainer {
  width: inherit;
}

.header {
  width: 100%;
  padding: 1rem;
  margin-bottom: 0;
  background-color: #e6e6e6;
  border-top: 2px solid black;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-size: 0.75rem;
}

.subheader {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 0;
  background-color: #e6e6e6;
  text-transform: capitalize;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  cursor: pointer;
}

.sectionHeader {
  width: 100%;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 2.25;
  border-bottom: 1px solid #e6e6e6;
}

.subheader span:nth-of-type(1) {
  padding-right: 1rem;
}

.chevron {
  transition: transform 0.4s;
}

.sectionHeader .chevron {
  color: #999aa0;
  font-size: 0.75rem;
}

.content {
  height: 0;
  overflow-x: hidden;
  transition: height 0.4s;
}

.content > div {
  padding-left: 1rem;
  padding-right: 1rem;
}

.sectionContainer .content > div {
  padding-left: 0;
  padding-right: 0;
}

.container input[type="checkbox"],
.sectionContainer input[type="checkbox"] {
  display: none;
}

.container input[type="checkbox"]:checked ~ div.content,
.sectionContainer input[type="checkbox"]:checked ~ div.content {
  height: auto;
}

.container input[type="checkbox"]:checked ~ label span.chevron,
.sectionContainer input[type="checkbox"]:checked ~ label span.chevron {
  transform: rotate(-180deg);
}
