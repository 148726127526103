.container {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #333333;
}

.container p {
  font-size: 0.8rem;
  font-weight: 700;
  color: #000;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
}
