.input {
  border: 1px solid #ececec;
  padding: 0.75rem 0.375rem;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  color: #707070;
}

.input::placeholder {
  color: #919296;
}

.search {
  padding-left: 2.25rem;
  background-image: url(./search.svg);
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: 0.75rem center;
}

@media (min-width: 768px) {
  .input {
    padding: 1rem 0.5rem;
    font-size: 1rem;
  }

  .search {
    padding-left: 3rem;
    background-position: 1rem center;
  }
}
