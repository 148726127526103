.mcoe {
  height: 15rem;
  background: #333333 url(../../../../img/home/Sharepoint.png) no-repeat center right;
  background-size: cover;
}

.getintouch {
  height: 11.125rem;
  background: #333333;
  background-size: cover;
}

.tile {
  width: 100%;
  height: 100%;
  background-color: transparent;
  padding: 1rem;
}

@media (min-width: 768px) {
  .mcoe {
    height: 20rem;
  }
  .getintouch {
    height: 15rem;
  }
  .tile {
    padding: 2rem;
  }
}

@media (min-width: 1025px) {
  .mcoe {
    background-image: url(../../../../img/home/Sharepoint@2x.png);
  }
  .tile {
    padding: 5rem 0 0 5rem;
  }
}
