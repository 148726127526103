.home-view {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.home-intro {
  height: 15rem;
  padding: 5rem 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.home-appName {
  flex: 1;
}
.home-search {
  flex: 1;
}

.home-tile {
  height: 22.5rem;
}
.home-explore-portfolio-ctn {
  background: url(../../img/home/Explore_portfolio.png) no-repeat center;
  background-size: cover;
}

.home-request-app-ctn {
  background: #333333 url(../../img/home/Request_app.png) no-repeat top right;
  background-size: cover;
}

.home-get-to-ctn {
  background: #aeaeae url(../../img/home/Get_to_know.png) no-repeat center left;
  background-size: cover;
}

.home-mcoe-ctn {
  height: 20rem;
  background: #333333 url(../../img/home/Sharepoint.png) no-repeat center left;
  background-size: cover;
}

.home-deep-tile {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.36);
  padding: 1rem;
}

@media (min-width: 768px) {
  .home-tile {
    height: 30rem;
  }

  .home-deep-tile {
    width: 75%;
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.12);
  }
}

@media (min-width: 1025px) {
  .home-deep-tile {
    width: 50%;
    padding: 5rem 0 0 5rem;
  }
}
