.platforms,
.devices {
  display: flex;
  align-items: baseline;
}

.platforms .title,
.devices .title {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  padding-right: 0.9rem;
}

.platforms i {
  padding-right: 0.25rem;
}

.biggerIcons i {
  font-size: 1.5rem;
}

.rating {
  display: flex;
  width: 130px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  color: #666666;
}

.rating div {
  font-size: 10px;
  align-self: flex-end;
}

@media (min-width: 768px) {
  .platforms .title,
  .devices .title {
    padding-right: 1.25rem;
  }
}

@media (max-width: 768px) {
  .rating {
    width: 90px;
  }
  .rating div {
    display: none;
  }
}
