.container {
  width: 100%;
  font-family: "Volvo Novum SemiLight", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  display: flex;
}

.container input[type="checkbox"] {
  display: none;
}

.label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.blue {
  color: #1251b5;
}

.labelText {
  padding-left: 0.5rem;
}
