.apps-filter {
  margin-bottom: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.apps-filter h3 {
  margin-bottom: 0.5rem;
}

.airr-sidepanel .apps-filter .col-12 {
  flex: 0 0 100% !important;
  max-width: 100%;
}
