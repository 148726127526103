.front-view {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.front-view .admin-role {
  position: absolute;
  z-index: 10;
  color: #4d4e53;
  right: 0;
  padding: 0 0.4rem;
  font-size: 0.9rem;
}
.front-view .admin-role i {
  margin-right: 5px;
}
.front-view .airr-view.airr-scene {
  flex: 2 0 auto;
}
