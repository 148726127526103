.appportal-card {
  padding-top: 1rem;
  display: flex;
  flex: 1;
}

.appportal-card-person-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.appportal-card-photo {
  border-radius: 50%;
  background-image: url(./cards/avatar.png);
  background-size: cover;
  background-position: top left;
  filter: drop-shadow(0px 2px 4px #00000029);
}

.appportal-card-photo-regular {
  width: 75px;
  height: 75px;
}

.appportal-card-photo-scaled {
  width: 60px;
  height: 60px;
  margin-left: 7.5px;
  margin-right: 7.5px;
}

.appportal-card-title {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  color: #333333;
  margin-bottom: 0 !important;
}

.appportal-card-jobtitle {
  font-family: "Volvo Novum Semilight", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  color: #333333;
  margin-bottom: 0 !important;
  word-wrap: break-word;
}

.appportal-card-personname {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
}

.appportal-card a,
.appportal-card a:hover {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  color: #1251b5;
  text-decoration: none;
}

.appportal-card a i {
  color: #333333;
  padding-right: 0.5rem;
}

@media (min-width: 768px) {
  .appportal-card-photo-regular {
    width: 100px;
    height: 100px;
    filter: drop-shadow(0px 3px 6px #00000029);
  }

  .appportal-card-photo-scaled {
    width: 80px;
    height: 80px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
