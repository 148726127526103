.button {
  border: 1px solid black;
  background-color: transparent;
  min-width: 9rem;
  padding: 0.75rem 1rem;
  text-align: center;
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.button i {
  font-size: 0.5rem;
  padding-left: 0.125rem;
}

.button:hover {
  background-color: #ececec;
}

.alternate {
  border-color: white;
  color: white;
}

.alternate:hover {
  background-color: white;
  color: #333333;
  border-color: #333333;
}

.action,
.action:hover {
  background-color: #182871;
  color: white;
  border-color: transparent;
}

.filter {
  border: 1px solid #1251b5;
  margin: 0.1rem 0.25rem;
  border-radius: 2rem;
  min-width: 0;
  width: auto;
  padding: 0.25rem 1rem;
  font-size: 0.7rem;
  color: #1251b5;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.filter span {
  padding-right: 1rem;
}

.filter:hover {
  background-color: #1251b5;
  color: white;
}

.smaller {
  border-width: 1px;
  min-width: 0;
  width: 8rem;
  padding: 0.5rem 1rem;
  font-size: 0.7rem;
}

.smaller i {
  font-size: 0.5rem;
}

.action.smaller {
  background-color: #1251b5;
  border: 1.5px solid #1251b5;
  color: white;
  min-width: 0;
  width: 6rem;
}

.action[disabled],
.action.smaller[disabled] {
  background-color: #6fa1f1;
  color: white;
  border-color: transparent;
}

.action.smaller.secondary {
  background-color: white;
  border: 1.5px solid #1251b5;
  color: #1251b5;
  min-width: 0;
  width: 6rem;
}

@media (min-width: 768px) {
  .button {
    border-width: 1.5px;
    width: 12rem;
    padding: 1rem 1rem;
    font-size: 1rem;
  }

  .button i {
    font-size: 0.75rem;
    padding-left: 0.25rem;
  }

  .filter {
    border-width: 1px solid #1251b5;
    width: auto;
    padding: 0.25rem 1rem;
    font-size: 0.7rem;
  }

  .smaller {
    border-width: 1px;
    width: 8rem;
    padding: 0.5rem 1rem;
    font-size: 0.7rem;
  }

  .action.smaller {
    border-width: 1.5px;
    width: 6rem;
  }

  .filter i,
  .smaller i,
  .action.smaller i {
    font-size: 0.6rem;
  }
}
