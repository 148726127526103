.login-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.login-view .message {
  text-align: center;
  margin-bottom: 1rem;
}
.login-view .content-ctn {
  height: 100%;
  background: url(../img/login/Login_phone.png) no-repeat center;
  background-size: cover;
}

.login-view .loading {
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-ctn-loginSection {
  padding: 3.75rem 2rem;
  background-color: rgba(255, 255, 255, 0.9);
  margin: -1rem;
}

.header-ctn {
  margin-left: -26rem;
}

@media (min-width: 768px) {
  .login-ctn-loginSection {
    padding: 5rem 5rem;
  }
}

@media (min-width: 1025px) {
  .login-view .content-ctn {
    background-image: url(../img/login/Login_phone@2x.png);
  }

  .login-ctn-loginSection {
    padding: 5rem 12rem;
  }
}
