@font-face {
  font-family: "Volvo Novum Medium";
  src: url(./fonts/VolvoNovum/VolvoNovum-Medium.otf);
}

@font-face {
  font-family: "Volvo Novum Bold";
  src: url(./fonts/VolvoNovum/VolvoNovum-Bold.otf);
}

@font-face {
  font-family: "Volvo Novum Regular";
  src: url(./fonts/VolvoNovum/VolvoNovum-Regular.otf);
}

@font-face {
  font-family: "Volvo Novum Semilight";
  src: url(./fonts/VolvoNovum/VolvoNovum-SemiLight.otf);
}

#root {
  height: 100%;
}
#root > div {
  height: 100%;
}
.airr-navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  color: #000;
  z-index: 102;
}
.airr-navbar > div {
  height: 80px !important;
}

.airr-view {
  background-color: #fff;
}

.airr-sidepanel > div:last-child {
  border-right: 1px solid #ccc;
}

.admin-menu {
  display: inline-block;
}
.admin-menu > span {
  display: block;
  font-size: 0.8rem;
}
.admin-menuspan i {
  font-size: 1rem;
  vertical-align: middle;
}

.volvo-ui-cover {
  font-weight: 300;
  justify-content: flex-start;
}
.volvo-form-multiselect .title .fa {
  color: #000;
}
.volvo-standard-footer a {
  cursor: pointer;
}
.volvo-horizontal-menu.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
  background: url("../img/home/Logo.png") no-repeat 1rem center #ececec;
  background-size: 2.2rem auto;
}
.volvo-form-multiautocomplete-input .add-btn {
  margin-right: 0 !important;
}
.volvo-standard-footer {
  z-index: -1;
}
/* medium */
@media (min-width: 768px) {
  .airr-navbar .mock-title,
  .airr-navbar .title {
    font-size: 2rem;
  }
  .admin-menu > span {
    font-size: 1rem;
  }
}
/* large */
@media (min-width: 992px) {
  .admin-menu > span {
    margin-right: 10px;
    display: inline-block;
  }
  .admin-menu > span:last-child {
    margin-right: 0;
  }
  .admin-menuspan i {
    font-size: 1.2rem;
  }
  .volvo-horizontal-menu.sticky {
    background-position: 0.5rem center;
    background-size: 1.5rem auto;
  }

  .volvo-horizontal-menu-centered {
    justify-content: inherit !important;
  }

  .logo-ctn-image {
    height: 125px !important;
    width: 150px !important;
    margin-bottom: -15px !important;
  }

  .logo-ctn {
    padding-bottom: 0px !important;
  }
}
@media (min-width: 1100px) {
  .volvo-horizontal-menu.sticky {
    background-position: 1rem center;
    background-size: 2.2rem auto;
  }
}
