.image > div,
.promoImage > div {
  background-size: cover;
  background-position: center center;
  border-radius: 1rem;
}

.image > div {
  min-width: 5rem;
  min-height: 5rem;
}

.promoImage > div {
  width: 10rem;
  height: 10rem;
}

.imageFallback {
  background-image: url(./loading.svg);
  filter: drop-shadow(0px 3px 6px #00000029);
}

/* xlarge */
@media (min-width: 1200px) {
  .image > div {
    min-width: 7rem;
    min-height: 7rem;
  }
}
