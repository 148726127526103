.textbox {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  line-height: 15/16rem;
}

.alternate {
  color: white;
}

.blueBorder {
  border-left: 5px #27aae1 solid;
  padding-left: 10px;
  margin-left: -15px;
  width: 16.25rem;
}

@media (min-width: 768px) {
  .textbox {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
