.badge {
  display: inline-block;
  height: 50px;
  width: 145px;
  background-position: center center;
  margin: 0 0.5rem;
}

.intune {
  background-color: #0477d6;
  background-repeat: no-repeat;
  background-position: 0.25rem center;
  border-radius: 0.25rem;
  padding-left: 3rem;
  color: white;
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.625rem;
  line-height: 0.625rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

a:hover {
  text-decoration: none;
}

.intune span:nth-of-type(2) {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  line-height: 1.125rem;
}

.intune span:nth-of-type(3) {
  font-size: 0.5rem;
}
