.c-pointer {
  cursor: pointer;
}

.c-pointer i {
  font-size: 1.5em;
}

.row-expand-icon {
  display: none !important;
}

.react-grid-row-group div {
  height: 45px !important;
}

.react-grid-row-group div strong {
  position: absolute !important;
  text-transform: capitalize !important;
}
