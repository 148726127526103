.header {
  font-family: "Volvo Novum Bold", Arial, Helvetica, sans-serif;
  font-size: 1.375rem;
}

.titleHeader {
  font-size: 1.5rem;
}

.subHeader {
  font-family: "Volvo Novum Bold", Arial, Helvetica, sans-serif;
  font-size: 1.25rem;
  text-transform: none;
}

.section {
  font-family: "Volvo Novum Bold", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  text-transform: uppercase;
}

.alternate {
  color: white;
}

@media (min-width: 768px) {
  .header {
    font-size: 2.125rem;
  }

  .titleHeader {
    font-size: 2.5rem;
  }

  .subHeader {
    font-size: 1.6875rem;
  }
}
