.portfolio-view {
  min-height: 500px;
}
.portfolio-view .filters-col {
  padding-left: 0;
  padding-right: 0;
  border-right: 1px solid #ddd;
}
.portfolio-view .apps-ctn {
  flex-wrap: wrap;
}

.portfolio-view .app-block {
  padding: 1rem 0;
  display: flex;
  align-items: flex-start;
}

.portfolio-view .app-block:last-child {
  border-bottom: none;
}

.portfolio-view .app-block .app-details {
  flex: 1;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
}

.portfolio-view .app-block .app-details .app-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding-bottom: 1rem;
  border-bottom: 1px solid #ececec;
}

.portfolio-view .app-block .app-details .app-basic-info {
  width: 75%;
}

.portfolio-view .app-block .app-details .description {
  width: 0;
  height: 0;
  overflow: hidden;
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  line-height: 1.375rem;
  color: #333333;
}

.portfolio-view .app-block .app-details .button {
  width: 25%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.portfolio-view .app-block .app-details .app-platforms-devices {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.75rem;
  color: #333333;
  width: 100%;
  padding-top: 0.75rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-bottom: 1rem;
  border-bottom: 1px solid #aaaaaa;
}

.portfolio-view .app-block .app-details .platforms {
  width: 35%;
}

.portfolio-view .app-block .app-details .devices {
  width: 65%;
  display: flex;
  align-items: baseline;
}

.portfolio-view .app-block .name {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #333333;
}
.portfolio-view .app-block .brand {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
  line-height: 1.5rem;
  color: #333333;
}

.portfolio-view .extra-btn {
  background-color: #343a40;
  padding: 1px 2px;
  color: #e5e5e5;
  border-radius: 0.2rem;
  font-size: 0.9rem;
  font-weight: 600;
  cursor: pointer;
  display: inline-block;
  margin-right: 3px;
}
.portfolio-view .extra-btns-ctn {
  display: flex;
  padding-top: 0.15rem;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
}

.portfolio-view .search-row,
.portfolio-view .filters-row,
.portfolio-view .buttons-row {
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
}

.portfolio-view .search-row {
  align-items: flex-end;
}

.portfolio-view .filters-row {
  justify-content: flex-start;
  flex-wrap: wrap;
}

.portfolio-view .filters-row hr {
  border: 0;
  margin: 0;
  width: 100%;
}

.portfolio-view .buttons-row {
  justify-content: space-between;
}

.portfolio-view .options-ctn {
  padding-bottom: 0.15rem;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #fff;
  transition: background-color 500ms linear;
  border-top: 1px solid #ddd;
}

.portfolio-view .filters-col .apps-filter {
  transition: background-color 500ms linear;
  border-top: 1px solid #ddd;
}
.portfolio-view .filters-col.stick .apps-filter {
  position: fixed;
  left: 0;
  width: 25%;
  z-index: 1000;
  border-right: 1px solid #ddd;
  background-color: #eee;
  overflow-y: "scroll";
  overflow-x: "hidden";
}

.modal-title {
  font-family: "Volvo Novum Bold", Arial, Helvetica, sans-serif;
  font-size: 1.375rem;
}

/* medium */
@media (min-width: 768px) {
  .portfolio-view .filters-row,
  .portfolio-view .buttons-row {
    padding-top: 2rem;
    padding-bottom: 1rem;
  }

  .portfolio-view .app-block .apps-info {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    width: 100%;
    align-items: center;
  }

  .portfolio-view .app-block .app-details .app-basic-info {
    width: 35%;
  }

  .portfolio-view .app-block .app-details .description {
    width: 50%;
    height: auto;
    overflow: visible;
  }

  .portfolio-view .app-block .app-details .button {
    width: 15%;
  }

  .portfolio-view .app-block .name {
    font-size: 1.375rem;
    line-height: 2rem;
  }

  .portfolio-view .app-block .brand {
    font-size: 1.125rem;
    line-height: 2rem;
  }

  .portfolio-view .app-block .app-details .app-platforms-devices {
    font-size: 1rem;
    padding-top: 1rem;
  }

  .header {
    font-size: 2.125rem;
  }
}
/* large */
@media (min-width: 992px) {
  .portfolio-view .app-block .apps-info {
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    width: 100%;
    align-items: center;
  }
}
/* xlarge */
@media (min-width: 1200px) {
  .portfolio-view .filters-col.stick .apps-filter {
    width: 16.666667%;
  }
}
