.no-app-found {
  margin-top: 25%;
}

.app-view,
.app-view-internal {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  color: #333333;
}

.app-view {
  background-color: #fff;
}

.app-view-internal {
  background-color: #ebebeb;
}

.app-view .top-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.app-view .back-button {
  font-family: "Volvo Novum Semilight", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.app-view .back-button i {
  padding-right: 0.625rem;
  font-size: 0.625rem;
}

.app-name span {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1.375rem;
  line-height: 2.375rem;
}

.app-brand span {
  font-size: 1.125rem;
  line-height: 2.375rem;
}

.app-view dt,
.app-view-internal dt,
.app-view .volvo-form-multiselect .title,
.app-view .volvo-form-autocomplete-input label {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.75rem;
}

.app-view dd span,
.app-view-internal dd span,
.app-view .description,
.app-view-internal .description,
.app-view-internal span,
.app-view-internal a,
.app-view-internal a ~ i {
  font-size: 0.875rem;
  line-height: 1.625rem;
}

.app-view-internal a ~ i {
  margin-left: 1.25rem;
  cursor: pointer;
}

.app-view .description {
  text-align: justify;
}
.app-view .description > div {
  margin-bottom: 1rem;
}
.app-view .description > div:last-child {
  margin-bottom: 2rem;
}
.app-view .description textarea,
.app-view textarea {
  min-height: 10rem;
  margin-bottom: 1rem;
}
.app-view .gallery-ctn {
  position: relative;
}
.app-view .gallery-ctn h5 {
  font-weight: 600;
}
.app-view .gallery-ctn .img {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  display: block;
  min-height: 12rem;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  cursor: pointer;
}
.app-view .gallery-ctn .type-artworks.key-512 .img {
  width: 150px;
  height: 150px;
  min-height: 0;
}

.app-view .gallery-ctn .img > span {
  display: none;
  background-color: #fff;
  padding: 0.3rem;
}
.app-view .gallery-ctn .img:hover > span {
  display: inline-block;
}
.app-view .gallery-ctn img {
  max-width: 14rem;
  max-height: 14rem;
  display: inline-block;
  margin: 1rem;
}

.app-view .loader-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}
.app-view .loader-canvas .loader {
  top: 20%;
  transform: translateY(-50%);
}
.app-view .video-ctn {
  padding: 1rem 0;
}
.app-view .video-ctn video {
  width: 100%;
}
.app-view .video-ctn video.portrait {
  width: 340px;
}
.app-view .delete-app {
  display: inline-block;
  color: #e0260d;
  border: 1px solid #e0260d;
  padding: 0.4rem 1rem;
  cursor: pointer;
  margin-bottom: 3rem;
}
.app-view .delete-app:hover {
  background-color: #f2bab3;
}
.app-view .install-app {
  margin-bottom: 1rem;
}
.app-view .install-app a,
.app-view .install-app .single-button {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  margin-right: 1rem;
  border: 1px solid #ddd;
  transition: background-color 0.2s linear;
  position: relative;
  cursor: pointer;
}
.app-view .install-app a:hover,
.app-view .install-app .single-button:hover {
  background-color: #ededed;
}
.app-view .install-app i {
  color: #3f51b5;
  font-size: 2.5rem;
  vertical-align: middle;
}
.app-view .install-app.qa i {
  color: #a0bab8;
}
.app-view .install-app a span,
.app-view .install-app .single-button span {
  color: #a0bab8;
  position: absolute;
  font-weight: 600;
  font-family: "Arial Black", sans-serif;
  bottom: -12px;
  right: -12px;
}
.app-view .install-app a:hover span,
.app-view .install-app .single-button:hover span {
  text-decoration: none;
}

.app-view .simple-select {
  border-radius: 0rem;
  padding: 5px 20px 5px 5px;
  background-color: #eee;
  border: 1px solid #ccc;
}

.app-view-internal .internal-header {
  font-family: "Volvo Novum Medium", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  line-height: 2.375rem;
}

.app-view-internal .internal-header span {
  font-family: "Volvo Novum Regular", Arial, Helvetica, sans-serif;
  color: #999aa0;
}

.spin {
  animation: spin;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

/* small */
@media (min-width: 576px) {
  .app-view .gallery-ctn .img {
    min-height: 14rem;
  }
}
/* medium */
@media (min-width: 768px) {
  .app-view .gallery-ctn .img {
    min-height: 16rem;
  }
}
/* large */
@media (min-width: 992px) {
  .app-view .gallery-ctn .img {
    min-height: 13rem;
  }
}
/* xlarge */
@media (min-width: 1200px) {
  .app-view .gallery-ctn .img {
    min-height: 18rem;
  }
}
